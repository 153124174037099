import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { MAXIMUM_MENTOR_EXPERIENCE, MINIMUM_MENTOR_EXPERIENCE } from '../constants';

interface MentorFilterState {
    expertise: string[];
    country: string | undefined;
    totalExperienceMin: number;
    totalExperienceMax: number;
    totalRating: number;
    orderBy: string | undefined;
    query: string;
    fromDate: string;
    toDate: string;
}

const initialState: MentorFilterState = {
    expertise: [],
    country: undefined,
    totalExperienceMin: MINIMUM_MENTOR_EXPERIENCE,
    totalExperienceMax: MAXIMUM_MENTOR_EXPERIENCE,
    totalRating: 0,
    orderBy: '',
    query: '',
    fromDate: '',
    toDate: '',
};

export const mentorFiltersSlice = createSlice({
    name: 'mentorFilters',
    initialState,
    reducers: {
        setFindMentorFilters: (state, action: PayloadAction<Partial<MentorFilterState>>) => {
            state = { ...state, ...action.payload };
            return state;
        },
        clearFilters: state => {
            state = initialState;
            return state;
        },
    },
});

export const { setFindMentorFilters, clearFilters } = mentorFiltersSlice.actions;

export { initialState };

export default mentorFiltersSlice.reducer;
